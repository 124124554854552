const BASE_URL =process.env.REACT_APP_API_BASE_URL; 

const DRUPAL_BASE_URL=process.env.REACT_APP_API_DRUPALBASEURL;

const CUSTOMER_NAME = "customer_name";

const MERCHANT_ID = "merchant_id";

const MERCHANT_COMPANY_NAME = "merchant_company_name"

const LOGO = "logo";

const EMAIL = "email";

const STRIPE_CUST_ID = "stripe_cust_id"

const PAYMENT_METHOD_NOT_FOUND = "payment_method_not_found"

const TWO_WAY_AUTH = "two_way_auth"

const SESSID = "sessid";

const SESSION_NAME = "session_name";

const TOKEN = "token";

const validEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;;

const validPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?"!@#%&/,><\':;|_~`^\\]\\[\\)\\(]).{8,}');

const validAlphaNumeric = /^[a-z0-9_ ]+$/i;

const ValidNumeric = /^[0-9]+$/i;

const ValidateSpaceInString = new RegExp("\\s+");

module.exports = {
    BASE_URL, CUSTOMER_NAME, MERCHANT_ID,
    MERCHANT_COMPANY_NAME, LOGO, EMAIL, STRIPE_CUST_ID,
    PAYMENT_METHOD_NOT_FOUND, TWO_WAY_AUTH, SESSID,
    SESSION_NAME, TOKEN, 
    validEmail, 
    validPassword,
    validAlphaNumeric, ValidNumeric, 
    ValidateSpaceInString ,DRUPAL_BASE_URL 
}