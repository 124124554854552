import React from "react";
import AddCredits from "../AddCredits/AddCredits";

function TransferFunds(props) {
  return (
    <div class="rightContent ">
      <div id="content" className="inner-content-page" role="main">
        <div className="page_header">
          <div className="page_title b2b-title">
            <h1 className="page__title title" id="page-title">
              Transfer Funds
            </h1>
          </div>
          <div className="clear"></div>
          <AddCredits />
        </div>
      </div>
    </div>
  );
}

export default TransferFunds;
