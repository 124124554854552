import $ from "jquery";

export function tabLinks() {
  $(".clickme_tab a").on("click", function (e) {
    e.preventDefault();
    $(".clickme_tab a").removeClass("activelink");
    $(this).addClass("activelink");
    var tagid = $(this).data("tag");
    $(".list").removeClass("active").addClass("hide");
    $("#" + tagid)
      .addClass("active")
      .removeClass("hide");
    e.stopPropagation();
  });
}
export function stoplink() {
  $(".pager-next a").on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
  });
}

export function addActive() {
  $("#tooltip").on("click", function () {
    $(this).addClass("active");
    e.stopPropagation();
  });
}