import { BASE_URL } from "./constants/Constants";

export async function CommonAuthApiService(input,pathname) {

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");


  var urlencoded = new URLSearchParams();
  Object.entries(input).map(([key,value])=>{
    urlencoded.append(key , value)
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };
  
  return await fetch(`${BASE_URL}/walletOauth2/${pathname}`, requestOptions)
  .then(async(response) => {
    const data= await response.text();
    return { status: response.status,data:data}})
  .then((res) => {
      if(res.status==201)
      {
        return { status: res.status,data:res.data}
      }else{
      let temp = JSON.parse(res.data);
      return { status: res.status,data:temp}}
    })
  .catch(error => console.log('error', error));
  }
