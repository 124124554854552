import React from "react";
import CommonFooter from "../CommonComponents/CommonFooter";
import CommonHeader from "../CommonComponents/CommonHeader";
import CommonSideBar from "../CommonComponents/CommonSideBar";

function Layout(props) {
  const path = props.path;
  return (
    <div id="page">
      <div id="wrapper">
        <CommonHeader />
        <div className="mainContent">
          <CommonSideBar />
          {props.children}
          <CommonFooter />
        </div>
      </div>
    </div>
  );
}
export default Layout;
