import React, { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainContext from "../../services/MainContext";

import VirtualWallets from "../../Components/MainComponents/VirtualWallets/VirtualWallets";
import Transactions from "../../Components/MainComponents/Transactions/Transactions";
import TransferFunds from "../../Components/MainComponents/TransferFunds/TransferFunds";

import Login from "../../Components/AuthComponents/Login/Login";
import Otp from "../../Components/AuthComponents/Otp/Otp";

function AllRoutes() {
    const context = useContext(MainContext);
    const Auth = context.loginFlag;
    return (
        <BrowserRouter>
            <Routes>

                <Route path="" element={!Auth ? <Login /> : (context.email == "" && <Navigate to={"/virtualWallets"} /> )} />
                <Route path="login" element={!Auth && <Login />} />
                <Route path="otp" element={Auth ? <Otp/> : <Navigate to="/" />} />

                <Route path="virtualWallets" element={Auth ? <VirtualWallets/> : <Navigate to="/" />} />
                <Route path="transactions" element={Auth ? <Transactions/> : <Navigate to="/" />} />
                <Route path="transferFunds" element={Auth ? <TransferFunds/> : <Navigate to="/" />} />

                <Route path="*" element={Auth ? <VirtualWallets/> : <Navigate to="/" />} />

            </Routes>
        </BrowserRouter>
    );
}

export default AllRoutes;
