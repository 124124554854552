import React from "react";
import footerlogo from "../../images/footerlogo.png";
function CommonFooter(props) {
  return (
    <footer>
      <span>Payment is powered by</span>
      <a href="#">
        <img src={footerlogo} alt="" height="30" />
      </a>
      © 2022 LOQ8 Master Wallet | <a href="#">Privacy Policy</a> |
      <a href="#">Terms and Conditions</a>
    </footer>
  );
}

export default CommonFooter;
