import React, { useState, useEffect } from 'react';
import MainContext from "./services/MainContext";
import AllRoutes from './services/Routes/AllRoutes';
function App() {
  const [loginFlag, setLoginFlag] = useState(false);
  const [amountFlag, setAmountFlag] = useState(0);
  const [email, setEmail] = useState("");
  const [virtualWalletList, setVirtualWalletList] = useState([]);


  const readSession = async () => {
    const LOQ8Data = localStorage.getItem("LOQ8");
    const obj = JSON.parse(LOQ8Data);
    if (obj) {
      setLoginFlag(true);
      setEmail("");
    } else {
      setLoginFlag(false);
    }
  };

  useEffect(() => {
    readSession();
  }, [])


  return (
    <React.Fragment>
      <MainContext.Provider
        value={{
          email, setEmail, loginFlag, setLoginFlag, amountFlag,
          setAmountFlag, virtualWalletList, setVirtualWalletList
        }} >
        <AllRoutes />
      </MainContext.Provider>
    </React.Fragment>
  );
}

export default App;