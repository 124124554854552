import React, { useState, useEffect, useContext } from "react";
import {
  CommonHomeApiService
} from "../../../services/HomeService";
import {
  centsToDollor,
  dateFormate,
  phoneNo,
} from "../../../services/Utility";
import AddCredits from "../AddCredits/AddCredits";
import Layout from "../LayOut";
import { tabLinks } from "../showHide.js";
import SucessfullSubmitPopUp from "../SucessfullSubmitPopUp/SucessfullSubmitPopUp";
import { useNavigate } from "react-router-dom";
import MainContext from "../../../services/MainContext";
import Loader from "../../CommonComponents/Loader";

function Transactions(props) {
  const navigate = useNavigate();
  const context = useContext(MainContext);

  const [offSet1, setOffSet1] = useState(0);
  const [limit1, setLimit1] = useState(10);
  const [limit2, setLimit2] = useState(10);
  const [offSet2, setOffSet2] = useState(0);


  const [obj, setObj] = useState([]);
  const [objLength, setObjLength] = useState(0);

  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState(0);


  const HandleObj = (resp) => {
    const oldObj = obj;
    oldObj.push(...resp);
    setObj(oldObj);
  }

  const HandleData = (resp) => {
    const oldData = data;
    oldData.push(...resp);
    setData(oldData);
  }


  const TransList = async () => {
    const input = {
      query:
        "query($filter: WalletOrderFilter, $sort:WalletOrderSorter, $limit:Int!, $offset: Int){walletOrders(filter:$filter, sort: $sort, limit: $limit,offset: $offset){data{id,state,createdAt,totalAmount,walletOrderType,sourceUser{id,firstName,lastName,name,logoUrl,isMasterUser,emailId,phone},targetUser{id,firstName,lastName,name,logoUrl,isMasterUser,emailId,phone}}}}",
      variables: {
        sort: {
          createdAt: "DESC",
        },
        filter: {
          createdAt: {
            gte: "2022-01-01T00:00:00.000Z",
            lte: "2030-12-31T24:00:00.000Z",
          },
          state: {
            os: [
              "COMPLETED",
              "SETTLED",
              "PROCESSING_CASHBACK",
              "ORDER_CONFIRMED",
              "FAILED",
            ],
          },
        },
        limit: limit1,
        offset: offSet1,
      },
    };
    const resp = await CommonHomeApiService(input);
    if (resp.status == 200) {
      setObjLength(resp.data.data.walletOrders.data.length);
      HandleObj([...resp.data.data.walletOrders.data]);
    }
    if (resp == false) {
        setObj([]);
        context.setLoginFlag(false);
        navigate("/");
      }
      if (resp == true) {
        setObj([]);
        TransList();
    }
    setOffSet1(limit1 + offSet1);
    setLimit1(limit1);
  };
  const BankList = async () => {
    const dataInput = {
      query:
        "query($filter: BankOrderFilter, $sort:BankOrderSorter, $limit:Int!, $offset: Int){bankDebitOrders( filter:$filter, sort: $sort, limit: $limit,offset: $offset){data{id,orderTokenNumber,state,createdAt,amount,attemptNumber,bankDetails{accountNumber,routingNumber,bankName,logo},linkedBankOrder{orderTokenNumber,amount,createdAt}}}}",
      variables: {
        sort: {
          createdAt: "DESC",
        },
        filter: {
          createdAt: {
            gte: "2022-01-01T00:00:00.000Z",
            lte: "2030-12-31T24:00:00.000Z",
          },
        },
        limit: limit2,
        offset: offSet2,
      },
    };
    const response = await CommonHomeApiService(dataInput);
    if (response.status == 200) {
      setDataLength(response.data.data.bankDebitOrders.data.length);
      HandleData([...response.data.data.bankDebitOrders.data])
    }
    if (response == false) {
        setData([]);
        context.setLoginFlag(false);
        navigate("/");
      }
      if (response == true){
        setData([]);
        BankList();
      };
    
    setOffSet2(limit2 + offSet2);
    setLimit2(limit2);
  };

  const loadMore1 = (e) => {
    TransList();
  }

  const loadMore2 = () => {
    BankList();
  }

  useEffect(() => {
    TransList();
    BankList();
  }, []);
  useEffect(() => {
    tabLinks();
  }, []);
  return (
    <Layout>
      <div className="rightContent ">
        <div id="content" className="" role="main">
          <div className="page_header">
            <div className="page_title b2b-title">
              <h1 className="page__title title" id="page-title">
                Transactions
              </h1>
            </div>
            <div className="transfer_funds">
              <ul class="trans_user">
                <li class="clickme_tab">
                  <a href="javascript:void();" data-tag="one" class="activelink">
                    Wallet Transfers
                  </a>
                </li>
                <li class="clickme_tab">
                  <a href="javascript:void();" data-tag="two">
                    Bank Transfers
                  </a>
                </li>
              </ul>
              <div class="list" id="one">
                <div class="tableResponsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Transaction ID</th>
                        <th>User Type</th>
                        <th>User Name</th>
                        <th>Amount</th>
                        <th>Date/Time</th>
                        <th>Order Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {obj.length != 0 &&
                        obj.map((data, index) => {
                          return (
                            <tr id={index}>
                              <td>{data.id}</td>
                              <td>
                                {!data.targetUser.emailId ? "Consumer" : "Merchant"}
                              </td>
                              <td>
                                {!data.targetUser.emailId
                                  ? phoneNo(data.targetUser.phone)
                                  : data.targetUser.emailId}
                              </td>
                              <td className={data.sourceUser.isMasterUser ? "redText" : "greenText"}>
                                {data.sourceUser.isMasterUser
                                  ? `-$${centsToDollor(data.totalAmount)}`
                                  : `+$${centsToDollor(data.totalAmount)}`}
                              </td>
                              <td>{dateFormate(data.createdAt)}</td>
                              <td>{data.walletOrderType}</td>

                              <td>{data.state}</td>
                            </tr>
                          );
                        })}

                    </tbody>
                  </table>
                  {obj.length === 0 && <Loader width={"110"} height={"110"} />}
                </div>
                <div className="item-list">
                  <ul className="pager">
                   {objLength>limit1-1 && <li className="pager-next"><a title="Go to next page" style={{ cursor: "pointer" }} onClick={loadMore1}>Load more ›</a></li>}
                  </ul>
                </div>
              </div>

              <div class="list hide" id="two">
                <div class="tableResponsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Transaction ID</th>
                        <th>Amount</th>
                        <th>Account Last 4 digit</th>
                        <th>Date/Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((data, index) => {
                        return (
                          <tr id={index}>
                            <td>{data.id}</td>
                            <td>{`$${centsToDollor(data.amount)}`}</td>
                            <td>{data.bankDetails.accountNumber.slice(5, 9)}</td>
                            <td>{dateFormate(data.createdAt)}</td>
                            <td>{data.state}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {data.length === 0 && <Loader width={"110"} height={"110"} />}
                </div>
                <div className="item-list">
                  <ul className="pager">
                   {dataLength>limit2-1 && <li className="pager-next"><a title="Go to next page" style={{ cursor: "pointer" }} onClick={loadMore2}>Load more ›</a></li>}
                  </ul>
                </div>
              </div>
            </div>
            <AddCredits />
            <SucessfullSubmitPopUp />

            <div className="clear"></div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Transactions;
