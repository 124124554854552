import react from "react";
import {ThreeDots} from "react-loader-spinner";

function Loader(props){
    return (
            <center className="LoaderCenter">
            <ThreeDots 
            
            height={props.height}
            width={props.width}
            style={{    display: "inline-block"}}
            color="purple" />  
            </center>
    )

}
export default Loader;