import React, { useContext } from "react";
import cross from "../../../images/close.png";
import { CommonHomeApiService, EmailVerify} from "../../../services/HomeService";
import { closeChangeAmount, disableVirtualWalletClose, sucessPopUP, sucessPopUpClose, sucesspopUpCloseDisable, sucesspopUPDisable } from "../../../services/jQuery/jQuery";
import { dollorToCents } from "../../../services/Utility";
import MainContext from "../../../services/MainContext";
import { useNavigate } from "react-router-dom";
import { displayVirtualWalletList } from "../../../services/Utility/CommonApiCall";
function DisableVirtualWallet(props) {
  const navigate = useNavigate();
  const context = useContext(MainContext);

  const CancelHandler = () => {
    disableVirtualWalletClose();
  }

  const DisableData=async()=>{
    const input={
    "email": props.email
    }
    const resp = await EmailVerify(input); 
    if(resp.status==200)
    {
    const data=resp.data;
    const input={
    "query": "mutation($In: MerchantCreate!) {updateVirtualWalletUserFlag(in:$In){id,loq8UserId,name,businessType,logoUrl,isVirtualWalletUser}}",
    "variables": {
    "In": {
    "emailId": data.merchant_email,
    "loq8UserId": data.merchant_id,
    "name": data.merchant_name,
    "businessType": data.merchant_type,
    "logoUrl": data.merchant_logo,
    "isVirtualWalletUser": false
    }
    }}

    const response = await CommonHomeApiService(input);
    
    if (response.status == 200) {
    const resp=await displayVirtualWalletList();
     if(resp.status==200)
     {
       context.setVirtualWalletList(resp.data.data.merchants.data);
       disableVirtualWalletClose();
       sucesspopUPDisable();
       sucesspopUpCloseDisable();
     }
    }
    if (response == false) {
    context.setLoginFlag(false)
    navigate("/");
    }
    if (response == true) {
    DisableData();
    }} 
    }

  return (
    <div>
      <div class="Disable_Wallet_Box" id="Disable_Wallet_Box">
        <div class="headerPart">
          <h2>Disable Account</h2>
          <a onClick={CancelHandler}>
            <img src={cross} style={{cursor:"pointer"}} /> </a>
        </div>

        <div class="payment_req">

          <div class="bulk_uploads add_credits">
            <div class="transfer_funds_form">
              <h2>Are you sure you want to disable virtual wallet from this merchant account ?</h2>
            </div>

            <div class="popup_btn">
              <div class="btn-group">
                <button type="button" class={"btn-save"} onClick={DisableData}> Disable </button>
              </div>

              <div class="btn-group">
                <button type="button" class="btn-cancel" onClick={CancelHandler} >Cancel</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  )
}

export default DisableVirtualWallet;