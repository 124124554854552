import { dollorToCents } from ".";
import { getAccessToken ,CommonAuthApiService} from "../AccountService";
import { CommonHomeApiService } from "../HomeService";





export const refreshtokenApiCall = async () => {
   
    let Loq8Obj = localStorage.getItem("LOQ8");
    let val = JSON.parse(Loq8Obj);
    let reftoken = val.refreshToken;

    const input = {
        refresh_token: reftoken,
        grant_type: 'refresh_token',
        client_id: "pnws"
    }
    const response = await CommonAuthApiService(input,"token");
    if (response.status == 200) {
        const temp = response.data;
        temp.refreshToken = reftoken;
        localStorage.setItem("LOQ8", JSON.stringify(temp));
        return true;
    }
    else {
        localStorage.removeItem("LOQ8");
        return false;
    }
}

export const addBalance = async(data,amount)=>{
    const inputValues = {
        "query": "mutation($creditTokens:Int!,$loq8UserId:String!) {transferCreditTokens(creditTokens:$creditTokens,loq8UserId:$loq8UserId){id,orderTokenNumber,createdAt,id,orderAmount}}",
        "variables": {
          "creditTokens": parseInt(dollorToCents(amount)),
          "loq8UserId": data.merchant_id
        }
      }
       const respData = await CommonHomeApiService(inputValues);
       return respData;
}


export const displayVirtualWalletList =async()=>{
    
    const input = {
        "query": "query($filter: MerchantFilter,$limit: Int!){merchants(filter: $filter, limit: $limit){data{id, name, emailId,loq8UserId,businessType,logoUrl,balanceTokens,isVirtualWalletUser}}}",
        "variables": {
          "filter": {
            "isVirtualWalletUser": {
              "eq": true
            }
          },
          "offset": 0,
          "limit": 10
        }
      }
      const list = await CommonHomeApiService(input);
      return list;
}


export const avaliableBalance =async()=>{
const input = {
    "query": "query { walletUserProfile{balanceTokens}}"
  }
  const resp = await CommonHomeApiService(input);
  return resp;
}