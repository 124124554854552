import React, { useState ,useContext } from "react";
import cross from "../../../images/close.png";
import MainContext from "../../../services/MainContext";
import { useNavigate } from "react-router-dom";
import { closeVirtualWallet, sucesspopUP, sucesspopUpClose } from "../../../services/jQuery/jQuery";
import { validEmail } from "../../../services/constants/Constants";
import { CommonHomeApiService, EmailVerify } from "../../../services/HomeService";
import { centsToDollor } from "../../../services/Utility";
import { addBalance, avaliableBalance, displayVirtualWalletList } from "../../../services/Utility/CommonApiCall";

function EnableVirtualWallet() {
  const navigate = useNavigate();
  const context = useContext(MainContext);

  const [email, setEmail] = useState("");
  const [apiError, setApiError] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);

  const cancel = () => {
    closeVirtualWallet();
  }

 const handleAmount = (e) => {
    if (e.target.value.length === 0 || e.target.value == 0) {
      setAmountError(true)
    } else {
      setAmountError(false)
    }
    const re = /^[0-9]*(\.[0-9]{0,2})?$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      if((context.amountFlag/100)<e.target.value)
      {
        setAmountError(true)
      }
      if (e.target.value == 0) {
        setAmount("")
      } else {
        setAmount(e.target.value)
      }
    }
  }

  const SubmitHandler = async() => {
   const input={
    "email": email
   }
   const resp = await EmailVerify(input); 
   if(resp.status==200)
   {
      const data=resp.data;

      const input={
        "query": "mutation($In: MerchantCreate!) {updateVirtualWalletUserFlag(in:$In){id,loq8UserId,name,businessType,logoUrl,isVirtualWalletUser}}",
        "variables": {
            "In": {
                "emailId": data.merchant_email,
                "loq8UserId": data.merchant_id,
                "name": data.merchant_name,
                "businessType": data.merchant_type,
                "logoUrl": data.merchant_logo,
                "isVirtualWalletUser": true
            }
        }}

     const response = await CommonHomeApiService(input); 
     if(response.status==200)
     {

      const list = await displayVirtualWalletList(); 
      context.setVirtualWalletList(list.data.data.merchants.data);
      if(list.status==200)
      {
      const addAmount=await addBalance(data,amount);
      if(addAmount.status==200)
      {
        const resp=await avaliableBalance();
        context.setAmountFlag(resp.data.data.walletUserProfile.balanceTokens);
        if(resp.status==200)
        {
          const listData = await displayVirtualWalletList(); 
          context.setVirtualWalletList(listData.data.data.merchants.data);
        }
      }}

      setAmount("");
      setEmail(""); 
      closeVirtualWallet();
      sucesspopUP();
      sucesspopUpClose();
     }
     if (response == false) {
        context.setLoginFlag(false)
        navigate("/");
      }
     if (response == true) {
       SubmitHandler();
      }
    }
  if(resp.status==406)
  {
    setApiError(true);
  }
  }

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setApiError(false);
    if (!validEmail.test(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (e.target.value.length == 0) {
      setEmailError(false);
    }
  };

  return (
    <div class="enable_wallet_box" id="enable_wallet_box">
      <div class="headerPart">
        <h2>Enable Virtual Wallet</h2>
        <a onClick={cancel} style={{ cursor: "pointer" }} >
          <img src={cross} /> </a>
      </div>

      <div class="payment_req">

        <div class="bulk_uploads add_credits">


          <div class="transfer_funds_form">

            <div class="inputBoxDiv">
              <input
                type="text"
                autocomplete="off"
                onChange={handleEmail}
                id="cardName"
                name="cardName"
                className={
                  emailError
                    ? "inputBox is-invalid"
                    : email.length > 0
                      ? "inputBox is-valid"
                      : "inputBox"
                }
                placeholder="Enter email"
                value={email}
              />
            </div>
            <div class="inputBoxDiv">
            <input type="number" autocomplete="off" 
                             onChange={handleAmount}
                             onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                             min={1}
                            value={amount} id="cardName" name="cardName" class="inputBox"
                            
                            className={
                                amountError
                                  ? "inputBox is-invalid"
                                  : amount.length > 0
                                    ? "inputBox is-valid"
                                    : "inputBox"
                              }
                              placeholder="Enter Amount ($)"
                            />
            </div>
          </div>

          {apiError && <p style={{
          marginTop: "-40px",
          marginBottom:"0px",
          fontSize: "15px",
          color: "red",
        }}>Please enter valid email id </p>}

          <div class="popup_btn">
            <div class="btn-group">
              <button type="button"
                className={emailError || amountError? "Btn disabled" :
                 ( email.length == 0 || amount=="") ? "Btn disabled" : "Btn"}
                onClick={(!emailError && !apiError && email.length > 0 && !amountError && amount>0) ? SubmitHandler : null}> Enable </button>
            </div>
            <div class="btn-group">
              <button type="button" class="btn-cancel" onClick={cancel}>Cancel</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default EnableVirtualWallet;