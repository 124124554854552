import React from 'react';

const CommonButton = ({ label, handleClick ,className , href,style}) => (
  <a
    href={href}
    className={className}
    // style={buttonStyle}
    onClick={handleClick}
    style={style}
  >
    {label}
  </a>
);

export default CommonButton;