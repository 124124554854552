import React, { useState,useEffect ,useContext } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import MainContext from "../../../services/MainContext";
import { CommonAuthApiService} from "../../../services/AccountService";
import CommonButton from "../../CommonComponents/CommonButton";

import { CommonHomeApiService} from "../../../services/HomeService";
import CommonAuth from "../../CommonComponents/CommonAuth";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from "history";

function Otp() {
   
  const navigate = useNavigate();
  const context = useContext(MainContext);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState(false);


  const ReSendOtp = async () => {
    const input = {
      restore_type: "email",
      email_account: context.email,
      client_id: "pnws",
      user_role: "Admin",
    };
    const resp = await CommonAuthApiService(input,"restore");
    if (resp.status == 201) {
      toast("OTP is sent :", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        progressStyle:{color:"red"},
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        
        });
    }
  }

  const avaliableBalance = async () => {
    const input = {
      "query": "query { walletUserProfile{balanceTokens}}"
    }
    const resp = await CommonHomeApiService(input);
    if (resp.status == 200) {
      context.setAmountFlag(resp.data.data.walletUserProfile.balanceTokens);
      context.setEmail("");
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const input = {
      code: `${context.email}|${otp}`,
      grant_type: "authorization_code",
      client_id: "pnws",
    };
    const resp = await CommonAuthApiService(input,"token");
    if (resp.status == 200) {
      localStorage.setItem("LOQ8", JSON.stringify(resp.data));
      avaliableBalance();
      setTimeout(() => {
        navigate("/virtualWallets");
      }, 2000);
    }
    if (resp.status == 401) {
      setOtpError(true)
      setLoading(false)
    }
  };

  const handleChange = (otp) => {
    setOtp(otp);
  }

let history = createBrowserHistory();
history.listen(({ location, action }) => {
  if(action === 'POP'){
   context.setLoginFlag(false);
}});

  return (
    <CommonAuth>
      <React.Fragment>
        <span>
          To continue, please enter the 4-digit verification code sent to your
          registered email address <a href="#">{context.email}</a>
        </span>

        <form
          method="get"
          className="digit-group"
          data-group-name="digits"
          data-autosubmit="false"
          autocomplete="off"
        >
          <OtpInput
            value={otp}
            onChange={(otp) => handleChange(otp)}
            containerStyle={{}}
            inputStyle={{
              lineHeight: 2,
              width: 40,
              fontSize: 27,
            }}
            isInputNum={true}
          />
        </form>

        <div className="button_Custom">
          {otpError && <p style={{
            marginTop: "-40px",
            fontSize: "15px",
            color: "red",
            marginBottom: "-10px"
          }}>Please enter valid otp </p>}
          <CommonButton
            className={otp.length > 3 ? "Btn " : "Btn disabled"}
            handleClick={otp.length > 3 ? submitHandler : null}
            label={
              <div>
                <span>{loading && "Please Wait..."}</span>
                {loading && <div className="loaderSpinner"></div>}
                {!loading && "Submit"}
              </div>
            }
          />

          <p className="resend_prea">
            Didn't receive a code? <a style={{ cursor: "pointer" }} onClick={ReSendOtp}>Resend</a>
          </p>
        </div>

        <ToastContainer
          // position="top-center"
          // autoClose={3000}
          // hideProgressBar={false}
          // newestOnTop={false}
          // closeOnClick
          toastStyle={{ backgroundColor: "white",color:"black" }}

          // rtl={false}
          // pauseOnFocusLoss
          // draggable
          // pauseOnHover 
          />

      </React.Fragment>
    </CommonAuth>
  );
}
export default Otp;
