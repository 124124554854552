import Login from "../AuthComponents/Login/Login";
import Otp from "../AuthComponents/Otp/Otp";
import React from "react";
import logo from "../../images/logo_login.png";
function CommonAuth(props) {
  const path = props.path;
  return (
    <body className="login_bg">
      <div className="login_form_Box">
        <div className="login_inner">
          <div className="logoPart">
            <img src={logo} alt="abc" />
            <h2>LOQ8 Master Wallet</h2>
          </div>

          <div className="form_input">
            <p>Log in to your account</p>
            <div>
              {props.children}
            </div>
          </div>
          <div class="formFooter">
            <a href="#" target="_blank">
              Privacy Policy
            </a>
            |
            <a href="#" target="_blank">
              Terms and Conditions
            </a>
          </div>
        </div>
      </div>
      <p class="versioningBox">{`v${process.env.REACT_APP_VERSION} ${process.env.REACT_APP_ENVIRONMENT}`}</p>
    </body>
  );
}

export default CommonAuth;
