import React from "react";

function SucessfullSubmitPopUp(){
 return (
 
 <div className="create_successfull_box" id="create_successfull_box">
  <h2>Credit successfully added</h2>
<div className="payment_req">
<div className="bulk_uploads add_credits">
<svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
</div>
</div>
</div>)
}
export default SucessfullSubmitPopUp;