import React from "react";
import { Link } from "react-router-dom";
function CommonSideBar() {
  return (
    <div id="leftBar" class="leftBar">
      <ul>
        <li className={location.pathname === '/virtualWallets' && 'active'} >
          <Link to = '/virtualWallets'>Virtual Wallets</Link>
        </li>


        {/* <li className={location.pathname === '/transferFunds' && 'active'} >
          <a href="/transferFunds" >Transfer Funds</a>
        </li> */}

        <li className={location.pathname === '/transactions' && 'active'} >
          <Link to = '/transactions'>Transactions</Link>
        </li>
      </ul>
    </div>
  );
}

export default CommonSideBar;
