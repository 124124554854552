import React, { useState, useContext } from "react";
import cross from "../../../images/close.png";
import { CommonHomeApiService} from "../../../services/HomeService";
import { closeChangeAmount, sucessPopUP, sucessPopUpClose } from "../../../services/jQuery/jQuery";
import { dollorToCents } from "../../../services/Utility";
import MainContext from "../../../services/MainContext";
import { useNavigate } from "react-router-dom";
function AddCredits() {
  const navigate = useNavigate();
  const context = useContext(MainContext);

  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);

  const CancelHandler = () => {
    closeChangeAmount();
  }

  const SubmitHandler = async () => {
    const input = {
      "query": "mutation($amount:Int!) {loadMasterWalletBalance(amount:$amount){id,orderTokenNumber,createdAt,amount,instantCreditAmount, penalty,state,bankDetails{ id,accountNumber},user{balanceTokens}}}",
      "variables": {
        "amount": parseInt(dollorToCents(amount))
      }
    }
    const resp = await CommonHomeApiService(input);
    if (resp.status == 200) {
      context.setAmountFlag(resp.data.data.loadMasterWalletBalance.user.balanceTokens);
      setAmount("");
      closeChangeAmount();
      sucessPopUP();
      sucessPopUpClose();
    }
    if (resp== false) {
        context.setLoginFlag(false);
        navigate("/");
      }
      if (resp== true) {
        SubmitHandler();
      }
    }

  

  const handleAmount = (e) => {
    if (e.target.value.length === 0 || e.target.value == 0) {
      setAmountError(true)
    } else {
      setAmountError(false)
    }
    const re = /^[0-9]*(\.[0-9]{0,2})?$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      if (e.target.value == 0) {
        setAmount("")
      } else {
        setAmount(e.target.value)
      }
    }
  }

  return (
    <div>
      <div class="add_credits_box" id="add_credits_box">
        <div class="headerPart">
          <h2>Add Credits</h2>
          <a onClick={CancelHandler}>
            <img src={cross} style={{cursor:"pointer"}} /> </a>
        </div>

        <div class="payment_req">

          <div class="bulk_uploads add_credits">
            <div class="transfer_funds_form">

              <div class="inputBoxDiv">

                <input
                  type="number"
                  onChange={handleAmount}
                  id="cardName"
                  name="cardName"
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  min={1}
                  className={
                    amountError
                      ? "inputBox is-invalid"
                      : amount.length > 0
                        ? "inputBox is-valid"
                        : "inputBox"
                  }
                  placeholder="Enter Amount ($)"
                  value={amount}
                />
              </div>

              {/* <p class="amunt_links">Amount will be deducted from the linked bank account ending with 6780.</p> */}
            </div>

            <div class="popup_btn">
              <div class="btn-group">
                <a ><button type="button"
                  class={amountError ? "btn-save disabled" :
                    amount.length == 0 ? "btn-save disabled" : "btn-save addBtn"}
                  onClick={amountError ? null : amount.length > 0 && SubmitHandler}> Add </button> </a>
              </div>

              <div class="btn-group">
                <button type="button" class="btn-cancel" onClick={CancelHandler}>Cancel</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  )
}

export default AddCredits;