import { BASE_URL , DRUPAL_BASE_URL} from "./constants/Constants";
import { headerFunction } from "./standardInputOutput/IO";
import { refreshtokenApiCall } from "./Utility/CommonApiCall";



export async function CommonHomeApiService(input) {
let requestOptions = {
method: "POST",
body: JSON.stringify(input),
headers: headerFunction(),
redirect: "follow",
};

return await fetch(`${BASE_URL}/graphql`, requestOptions)
.then(async (response) => {
const data = await response.text()
return { status: response.status, data: data }
})
.then((res) => {
let temp = JSON.parse(res.data);

if(res.status==200)
{
  return { status: res.status, data: temp } ;
}
if(res.status==401)
{
  const data= refreshtokenApiCall();
  return data;
}
})
.catch((error) => {
console.log("error", error);
return false;
});
}



export async function EmailVerify(input) {
  
var requestOptions = {
  method: 'POST',
  headers: {"Content-Type": "application/json"} ,
  body: JSON.stringify(input),
  redirect: 'follow'
};

return await fetch(`${DRUPAL_BASE_URL}/merchant_details/connect.json`, requestOptions)
.then(async(response) => {
  const data= await response.text();
  return { status: response.status,data:data}})
.then((res) => {
    let temp = JSON.parse(res.data);
    return { status: res.status,data:temp}
  })
.catch(error => console.log('error', error));
}
