import React, { useEffect, useState, useContext } from "react";
import AddCredits from "../AddCredits/AddCredits";
import Pluse from "../../../images/plus-icon.png";
// import MinusLogo from "../../../images/minus.png";
// import add from "../../../images/add.png";
// import min from "../../../images/min.png";
import SucessfullSubmitPopUp from "../SucessfullSubmitPopUp/SucessfullSubmitPopUp";
import { CommonHomeApiService } from "../../../services/HomeService";
import { centsToDollor, dollorToCents } from "../../../services/Utility";
import Layout from "../LayOut";
import MainContext from "../../../services/MainContext";
import { useNavigate } from "react-router-dom";
import Loader from "../../CommonComponents/Loader";
import EnableVirtualWallet from "../EnableVirtualWallet/EnableVirtualWallet";
import { activeVirtualWallet, disableVirtualWalletActive } from "../../../services/jQuery/jQuery";
import SucessfullPopUp from "../SucessfullSubmitPopUp/SucessfullPopUp";
import DisableVirtualWallet from "../DisableVirtualWallet/DisableVirtualWallet";
import Sucessfull from "../SucessfullSubmitPopUp/Sucessfull";

function VirtualWallets() {
  const context = useContext(MainContext);
  const navigate = useNavigate();

  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(true);
  const [email, setEmail] = useState("");


  useEffect(async () => {
    VirtualList();
    avaliableBalance();
  }, []);

const emptyAmount= ()=>{
  setAmount("");
}
  const VirtualList = async () => {

    const input = {
      "query": "query($filter: MerchantFilter,$limit: Int!){merchants(filter: $filter, limit: $limit){data{id, name, emailId,loq8UserId,businessType,logoUrl,balanceTokens,isVirtualWalletUser}}}",
      "variables": {
        "filter": {
          "isVirtualWalletUser": {
            "eq": true
          }
        },
        "offset": offSet,
        "limit": limit
      }
    }
    const resp = await CommonHomeApiService(input);


    if (resp.status == 200) {
      context.setVirtualWalletList(resp.data.data.merchants.data);
    }
    if (resp == false) {
      context.setVirtualWalletList([]);
      context.setLoginFlag(false)
      navigate("/");
    }
    if (resp == true) {
      context.setVirtualWalletList([]);
      VirtualList();
    };
  }

  const avaliableBalance = async () => {

    const input = {
      "query": "query { walletUserProfile{balanceTokens}}"
    }
    const resp = await CommonHomeApiService(input);
    if (resp.status == 200) {
      context.setAmountFlag(resp.data.data.walletUserProfile.balanceTokens);
    }
    if (resp == false) {
      context.setLoginFlag(false);
      navigate("/");
    }
    if (resp == true) {
      avaliableBalance();
    };

  }

  const DisableData = async (email) => {
    setEmail(email);
    disableVirtualWalletActive();
  }


  const LoadMore = () => {
    setOffSet(limit + 1);
    setLimit(limit + 10);
    VirtualList();
  }

  const dataChange = (e) => {
    // const re = /^[0-9]*(\.[0-9]{0,2})?$/;
    // if (e.target.value === '' || re.test(e.target.value)) {
    //   if (e.target.value == 0) {
    //     setAmount("")
    //   } else {
    //     setAmount(e.target.value)
    //   }
    // }
    if (e.target.value.length === 0 || e.target.value == 0) {
      setAmountError(true)
    } else {
      setAmountError(false)
    }
    const re = /^[0-9]*(\.[0-9]{0,2})?$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      if ((context.amountFlag / 100) < e.target.value) {
        setAmountError(true)
      }
      if (e.target.value == 0) {
        setAmount("")
      } else {
        setAmount(e.target.value)
      }
    }
  }
  const addBalance = async (id) => {
    const input = {
      "query": "mutation($creditTokens:Int!,$loq8UserId:String!) {transferCreditTokens(creditTokens:$creditTokens,loq8UserId:$loq8UserId){id,orderTokenNumber,createdAt,id,orderAmount}}",
      "variables": {
        "creditTokens": parseInt(dollorToCents(amount)),
        "loq8UserId": id
      }
    }
    const resp = await CommonHomeApiService(input);
    if (resp.status === 200) {
      setAmount("");
      VirtualList();
      avaliableBalance();
    }
    if (resp == false) {
      context.setLoginFlag(false);
      navigate("/");
    }
    if (resp == true) {
      addBalance(id);
    };
  }
  const enableVirtualWallet = () => {
    activeVirtualWallet();
  }
  return (
    <Layout>
      <div className="rightContent ">
        <div id="content" className="inner-content-page" role="main">
          <div className="page_header">
            <div className="page_title b2b-title">
              <h1 className="page__title title" id="page-title">
                Virtual Wallets
              </h1>
            </div>

            <div id="container">
              <div class="btn-right">
                <a onClick={enableVirtualWallet}>
                  Enable Virtual Wallet
                </a>
              </div>
            </div>

            <div className="clear"></div>

            <AddCredits />
            <SucessfullSubmitPopUp />
            <EnableVirtualWallet />
            <SucessfullPopUp />
            <DisableVirtualWallet email={email} />
            <Sucessfull />
            <div className="transfer_funds">

              <div id="container">

                <div style={{ overflow:"visible"}} className="tableResponsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Merchant ID </th>
                        <th>Merchant Email ID</th>
                        <th>Available Amount</th>
                        <th>Action</th>


                      </tr>
                    </thead>

                    <tbody>
                      {context.virtualWalletList.map((data, index) => {
                        return (
                          <tr id={index}>
                            <td>{data.loq8UserId}</td>
                            <td>{data.emailId}</td>
                            <td>
                              <div className="icon_tbl">${centsToDollor(data.balanceTokens)}</div>
                              <div className="tooltip">
                                <img src={Pluse} />
                                {/* onBlur={emptyAmount} */}
                                <span className="text" onMouseLeave={emptyAmount}>
                                  Add Credits
                                  <div className="tooltipBox" 
                                      >
                                    <input className={amountError
                                      ? "inputBoxTxt is-invalid"
                                      : amount.length > 0
                                        ? "inputBoxTxt is-valid"
                                        : "inputBoxTxt"}
                                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                      value={amount}
                                      onChange={(e) => dataChange(e)}
                                      type="number" />
                                    <img src={Pluse}
                                      className={amountError && "disabled"}
                                      onClick={() => { amount.length > 0 && addBalance(data.loq8UserId) }} />
                                  </div>
                                </span>
                              </div>
                            </td>
                            <td><a style={{ cursor: "pointer", color: "#161158" }} onClick={() => DisableData(data.emailId)}>Disable</a></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {context.virtualWalletList.length == 0 && <Loader width={"110"} height={"110"} />}

                </div>

                <div className="item-list">
                  <ul className="pager">
                    {context.virtualWalletList.length > limit - 1 && (<li className="pager-next"><a title="Go to next page" style={{ cursor: "pointer" }} onClick={LoadMore}>Load more ›</a></li>)}
                  </ul>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  );
}

export default VirtualWallets;
