import $ from "jquery";

  // add credit

export function activeChangeAmount() {
    $(".add_credits_box").addClass("animated zoomIn");
    $(".add_credits_box").show();
    $(".rightContent").append('<div class="overLay"></div');
    setTimeout(function () {
      $(".overLay").css("opacity", 1);
    }, 50);
  }
  export function closeChangeAmount() {
    $(".add_credits_box").removeClass("animated zoomIn");
    $(".add_credits_box").hide();
    $(".overLay").css("opacity", 0).delay(200);
    setTimeout(function () {
      $(".overLay").remove();
    }, 1000);
  }

  export function sucessPopUP() {
    $(".create_successfull_box").addClass("animated zoomIn");
    $(".create_successfull_box").parent().append('<div class="overLay2"></div');
    setTimeout(function () {
      $(".overLay2").css("opacity", 1);
    }, 50);
    $(".create_successfull_box").show();
  }
  export function sucessPopUpClose() {
    $(".overLay2").css("opacity", 0).delay(200);
    setTimeout(() => {
      $(".create_successfull_box").hide();
      $(".overLay2").remove();
    }, 2000);
  }



  //enable and disable virtual wallet

  export function activeVirtualWallet() {
    $(".enable_wallet_box").addClass("animated zoomIn");
    $(".enable_wallet_box").show();
    $(".rightContent").append('<div class="overLay"></div');
    setTimeout(function () {
      $(".overLay").css("opacity", 1);
    }, 50);
  }
  export function closeVirtualWallet() {
    $(".enable_wallet_box").removeClass("animated zoomIn");
    $(".enable_wallet_box").hide();
    $(".overLay").css("opacity", 0).delay(200);
    setTimeout(function () {
      $(".overLay").remove();
    }, 1000);
  }
  


  export function sucesspopUP() {
    $(".successfull_box").addClass("animated zoomIn");
    $(".successfull_box").parent().append('<div class="overLay2"></div');
    setTimeout(function () {
      $(".overLay2").css("opacity", 1);
    }, 50);
    $(".successfull_box").show();
  }
  export function sucesspopUpClose() {
    $(".overLay2").css("opacity", 0).delay(200);
    setTimeout(() => {
      $(".successfull_box").hide();
      $(".overLay2").remove();
    }, 2000);
  }

//Disable virtual wallet

  export function disableVirtualWalletActive() {
    $(".Disable_Wallet_Box").addClass("animated zoomIn");
    $(".Disable_Wallet_Box").show();
    $(".rightContent").append('<div class="overLay"></div');
    setTimeout(function () {
      $(".overLay").css("opacity", 1);
    }, 50);
  }
  export function disableVirtualWalletClose() {
    $(".Disable_Wallet_Box").removeClass("animated zoomIn");
    $(".Disable_Wallet_Box").hide();
    $(".overLay").css("opacity", 0).delay(200);
    setTimeout(function () {
      $(".overLay").remove();
    }, 1000);
  }
  


  export function sucesspopUPDisable() {
    $(".success_box").addClass("animated zoomIn");
    $(".success_box").parent().append('<div class="overLay2"></div');
    setTimeout(function () {
      $(".overLay2").css("opacity", 1);
    }, 50);
    $(".success_box").show();
  }
  export function sucesspopUpCloseDisable() {
    $(".overLay2").css("opacity", 0).delay(200);
    setTimeout(() => {
      $(".success_box").hide();
      $(".overLay2").remove();
    }, 2000);
  }
