import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CommonAuthApiService} from "../../../services/AccountService";
import { validEmail } from "../../../services/constants/Constants";
import MainContext from "../../../services/MainContext";
import CommonAuth from "../../CommonComponents/CommonAuth";
import CommonButton from "../../CommonComponents/CommonButton";

function Login() {

  const navigate = useNavigate();
  const context = useContext(MainContext);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setApiError(false);
    if (!validEmail.test(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (e.target.value.length == 0) {
      setEmailError(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const input = {
      restore_type: "email",
      email_account: email,
      client_id: "pnws",
      user_role: "Admin",
    };
    const resp = await CommonAuthApiService(input,"restore");
    if (resp.status === 201) {
      context.setEmail(email);
      context.setLoginFlag(true);
      navigate("/otp");
    } else {
      setLoading(false);
      setApiError(true);
    }
  };
  const preventHandler = async (e)=>{
    e.preventDefault();
  }
  return (
    <CommonAuth>
    <React.Fragment>
      <form onSubmit={(!emailError&&!apiError&&email.length>0)?submitHandler:preventHandler}>
      <div className="inputBoxDiv">
        <input
          type="text"
          autocomplete="off"
          onChange={handleEmail}
          id="cardName"
          name="cardName"
          className={
            emailError
              ? "inputBox is-invalid"
              : email.length > 0
                ? "inputBox is-valid"
                : "inputBox"
          }
          placeholder="Enter email"
        />
      </div>
      <div className="button_Custom">
        {apiError && <p style={{
          marginTop: "-40px",
          fontSize: "15px",
          color: "red",
          marginBottom: "-10px"
        }}>Please enter valid email id </p>}
        <CommonButton
          className={emailError?"Btn disabled":
          email.length==0?"Btn disabled":"Btn"}
          handleClick={(!emailError&&!apiError&&email.length>0)?submitHandler:null}
          label={
            <div>
              <span>{loading && "Please Wait..."}</span>
              {loading && <div className="loaderSpinner"></div>}
              {!loading && "Log In"}
            </div>
          }
        />
      </div></form>

    </React.Fragment>
    </CommonAuth>
  );
}
export default Login;
