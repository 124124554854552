import moment from "moment-timezone";

export const centsToDollor = (data) => {
  const options = { 
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  };
 const number= (data/100).toLocaleString('en', options)
  return number
 };

export const dollorToCents = (data) => (data*100);

export const dateFormate=(date)=>{
  return toDisplayFormat(date);
  const year=date.slice(0,4);
  const month=date.slice(5,7);
  const day=date.slice(8,10);
  return `${day}-${month}-${year}`
}

export const phoneNo=(data)=>{
  if(data && data.length == 10) {
    const front =data.slice(0,3);
    const back3 = data.slice(3,6);
    const last4=data.slice(6,10);
    return `+1 (${front}) ${back3}-${last4}`
  }
  return data
}

export const toDisplayFormat = (date) => {
  let timezone = 'America/Los_Angeles'; // moment.tz.guess()
  if(date) {
    return moment
    .tz(date.toString(), timezone)
    .format("MM-DD-YYYY hh:mm A z");
  }
  return ""
}
