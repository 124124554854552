import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import logout from "../../images/logout.svg";
import refresh from "../../images/refresh.png";
import MainContext from "../../services/MainContext";
import { activeChangeAmount } from "../../services/jQuery/jQuery";
import { centsToDollor } from "../../services/Utility";
import { CommonHomeApiService } from "../../services/HomeService";

function CommonHeader() {
  const navigate = useNavigate();
  const context = useContext(MainContext);

  const [loaderFlage, setLoaderFlage] = useState(false);

  const LogoutSystem = () => {
    localStorage.removeItem("LOQ8");
    context.setLoginFlag(false);
    navigate("/");
  };

  const AddCredit = () => {
    activeChangeAmount();
  }

  const avaliableBalance = async () => {
    const input = {
      "query": "query { walletUserProfile{balanceTokens}}"
    }
    const resp = await CommonHomeApiService(input);
    if (resp.status == 200) {
      context.setAmountFlag(resp.data.data.walletUserProfile.balanceTokens);
    }
    if (resp == false) {
        context.setLoginFlag(false);
        navigate("/");
      } 
    if (resp == true) {
        avaliableBalance();
      }
    }
  

  const updateAvalibleBalance = () => {
    avaliableBalance();
    setLoaderFlage(true);
    setTimeout(() => {
      setLoaderFlage(false);
    }, 1000)
  }

  return (
    <div id="header">
      <div className="logo">
        <div>
          <a href="index.html">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="logoPart">
          <p> LOQ8 Master Wallet</p>
        </div>
      </div>

      <div className="rightsidemenu">
        <div className="login_block">
          <div className="log_in">
            <div className="login_detail">
              <span>
                Available Balance:
                <b>{` $${centsToDollor(context.amountFlag)}`}</b>
              </span>
              
                <img
                  src={refresh}
                  alt=""
                  className={loaderFlage && "rotatingIcon"}
                  onClick={updateAvalibleBalance}
                />
              <div className="clear"></div>
            </div>
          </div>
        </div>

        <div className="btn-group-right">
          <a className="payWith"
            onClick={AddCredit}
          >
            Add Credit </a>
          <p
            onClick={LogoutSystem}
            title="Logout"
          >
            <img
              style={{ cursor: "pointer" }}
              src={logout}
              alt=""
            />
          </p>
        </div>
      </div>

    </div>
  );
}

export default CommonHeader;
